<template>
    <div class="navigation">
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <div class="container-fluid">
                <b-navbar-brand to="/">Crawler</b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item to="/"><i class="fa fa-dashboard"></i> Dashboard</b-nav-item>
                        <b-nav-item to="/campaigns"><i class="fa fa-bullseye"></i> Campaigns</b-nav-item>
                        <b-nav-item to="/proxies"><i class="fa fa-linux"></i> Proxies</b-nav-item>
<!--                        <b-nav-item to="/proxy-manager"><i class="fa fa-linux"></i> Proxy Manager</b-nav-item>-->
<!--                        <b-nav-item to="/services"><i class="fa fa-align-left"></i> Services</b-nav-item>-->
<!--                        <b-nav-item-dropdown>-->
<!--                            <template v-slot:button-content>-->
<!--                                <em><i class="fa fa-linux"></i> Proxy</em>-->
<!--                            </template>-->
<!--                            <b-dropdown-item to="/providers"><i class="fa fa-wifi"></i> Providers</b-dropdown-item>-->
<!--                            <b-dropdown-item to="/proxies"><i class="fa fa-link"></i> Proxies</b-dropdown-item>-->
<!--                        </b-nav-item-dropdown>-->
                        <b-nav-item to="/users"><i class="fa fa-user-circle-o"></i> Users</b-nav-item>
                        <b-nav-item to="/clients"><i class="fa fa-user"></i> Clients</b-nav-item>
                        <!-- ADOPS-109 -->
                        <b-nav-item-dropdown>
                            <template v-slot:button-content>
                                <em><i class="fa fa-cogs"></i> Tools</em>
                            </template>
                            <!-- A -->
                            <b-dropdown-item to="/reports/analytics"><i class="fa fa-clipboard"></i> Adalytics</b-dropdown-item>
                            <b-dropdown-item to="/automationLog"><i class="fa fa-clipboard"></i>Automation Log</b-dropdown-item>

                            <!-- B -->
                            <b-dropdown-item to="/bandwidth/inspect"><i class="fa fa-search"></i> Bandwidth Inspector</b-dropdown-item>

                            <b-dropdown-item to="/browser-search"><i class="fa fa-search"></i> Browser Search</b-dropdown-item>

                            <!-- C -->
                            <b-dropdown-item to="/campaign/needs"><i class="fa fa-book"></i> Campaign Needs Logs (Client Export)</b-dropdown-item>
                            <b-dropdown-item to="/captcha/detection"><i class="fa fa-book"></i> Captcha Detected</b-dropdown-item>
                            <b-dropdown-item to="/reports/catchers"><i class="fa fa-clipboard"></i> Catchers</b-dropdown-item>
                            <b-dropdown-item to="/chats"><i class="fa fa-comment"></i> Chat GPT</b-dropdown-item>
                            <b-dropdown-item to="/clicks/report"><i class="fa fa-clipboard"></i> City Clicks</b-dropdown-item>
                            <b-dropdown-item to="/shown-rate-config"><i class="fa fa-wrench"></i> Configurator</b-dropdown-item>

                            <!-- D -->
                            <b-dropdown-item to="/debug"><i class="fa fa-bug"></i> Debugger</b-dropdown-item>
                            <b-dropdown-item to="/devices"><i class="fa fa-desktop"></i> Devices</b-dropdown-item>
                            <b-dropdown-item to="/domain/bandwidth"><i class="fa fa-book"></i> Domain Usage</b-dropdown-item>

                            <!-- E -->
                            <b-dropdown-item to="/email"><i class="fa fa-envelope"></i> Emails</b-dropdown-item>
                            <b-dropdown-item to="/email configuration"><i class="fa fa-cog"></i>Email Configuration</b-dropdown-item>
                            <b-dropdown-item to="/exemption"><i class="fa fa-ban"></i> Traffic Exemption</b-dropdown-item>
                            <!-- F -->
                            <b-dropdown-item to="/tools/fulfillmentReport"><i class="fa fa-signal"></i> Fulfillment Report</b-dropdown-item>

                            <!-- G -->
                        

                            <!-- H -->
                            <b-dropdown-item to="/handlers"><i class="fa fa-clipboard"></i> Handlers Tool</b-dropdown-item>
                            <b-dropdown-item to="/device/health"><i class="fa fa-heartbeat"></i> Healthcheck</b-dropdown-item>

                            <!-- I -->
                            <b-dropdown-item to="/ip-blacklist"><i class="fa fa-ban"></i> IP Blacklist</b-dropdown-item>
                            <b-dropdown-item to="/tools/internal-billing"><i class="fa fa-money"></i> Internal Billing</b-dropdown-item>

                            <!-- N -->
                            <b-dropdown-item to="/nightlyConfig"><i class="fa fa-cog"></i> Nightly Config</b-dropdown-item>

                            <!-- P -->
                            <b-dropdown-item to="/precheck"><i class="fa fa-clipboard"></i> Precheck</b-dropdown-item>

                            <!-- Q -->
                            <b-dropdown-item to="/tools/qso"><i class="fa fa-signal"></i> QSO</b-dropdown-item>
                            <b-dropdown-item to="/tools/qso-client"><i class="fa fa-signal"></i> QSO (Client Export)</b-dropdown-item>

                            <!-- R -->
                            <b-dropdown-item to="/handlers/request"><i class="fa fa-clipboard"></i> Request Handler</b-dropdown-item>
                            <b-dropdown-item to="/ruleEngine"><i class="fa fa-clipboard"></i> Rule Config</b-dropdown-item>

                            <!-- S -->
                            <b-dropdown-item to="/services"><i class="fa fa-cog"></i> Services</b-dropdown-item>
                            <b-dropdown-item to="/scraper"><i class="fa fa-scissors"></i> Scraper</b-dropdown-item>
                            <b-dropdown-item to="/scraper/cookies"><i class="fa fa-cog"></i> Scraper Cookies</b-dropdown-item>
                            <b-dropdown-item to="/scraper/social"><i class="fa fa-scissors"></i> Social Scraper</b-dropdown-item>
                            <b-dropdown-item to="/snapshots"><i class="fa fa-book"></i> Snapshots</b-dropdown-item>


                            <!-- W -->
                            <b-dropdown-item to="/weeklyReport"><i class="fa fa-clipboard"></i>Weekly Clicks Report</b-dropdown-item>


                            <b-dropdown-item to="/emailconfig"><i class="fa fa-cog"></i>Email Configuration</b-dropdown-item>

                            <b-dropdown-item to="/BingIntegration"><i class="fa fa-search"></i>Integrate Bing Search</b-dropdown-item>                            <b-dropdown-item to="/yahooIntegration"><i class="fa fa-yahoo"></i> Yahoo Integration</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown>
                            <template v-slot:button-content>
                                <em><i class="fa fa-certificate"></i> Reports</em>
                            </template>

                            <!-- A -->
                            <b-dropdown-item to="/reports/alerts"><i class="fa fa-clipboard"></i> Alerts Report </b-dropdown-item>

                            <!-- B -->
                            <b-dropdown-item to="/coverage/report"><i class="fa fa-clipboard"></i> Bandwidth Report</b-dropdown-item>

                            <!-- C -->
                            <b-dropdown-item to="/serviceReport"><i class="fa fa-clipboard"></i> Campaign Capped & Shown Report </b-dropdown-item>
                            <b-dropdown-item to="/campaign/error/logs"><i class="fa fa-clipboard"></i> Campaign Error Logs - Counts</b-dropdown-item>
                            <b-dropdown-item to="/campaign/detailed/error/logs"><i class="fa fa-clipboard"></i> Campaign Error Logs - Detailed</b-dropdown-item>
                            <b-dropdown-item to="/campaign/campaign-statistics-report"><i class="fa fa-clipboard"></i> Campaign Statistics Report</b-dropdown-item>
                            <b-dropdown-item to="/capped-and-shown-report"><i class="fa fa-clipboard"></i> Capped & Shown Report</b-dropdown-item>
                            <b-dropdown-item to="/coverage/map"><i class="fa fa-clipboard"></i> Coverage Map</b-dropdown-item>
                            <b-dropdown-item to="/billing"><i class="fa fa-money"></i> Client Billing</b-dropdown-item>
                            <b-dropdown-item to="/clientsReport"><i class="fa fa-clipboard"></i> Client Report</b-dropdown-item>
                            <b-dropdown-item to="/sessionReport"><i class="fa fa-clipboard"></i> Client Session Validation</b-dropdown-item>
                            <b-dropdown-item to="/clickDistribution/report"><i class="fa fa-signal"></i> Click Distribution Report</b-dropdown-item>
                            <b-dropdown-item to="/cookiesReport"><i class="fa fa-clipboard"></i> Cookies Report</b-dropdown-item>

                            <!-- D -->
                            <b-dropdown-item to="/campaign/d-campaign-report"><i class="fa fa-clipboard"></i> Duplicate Campaign Report</b-dropdown-item>

                            <!-- E -->
                            <b-dropdown-item to="/campaign/e-campaign-report"><i class="fa fa-clipboard"></i> E-Campaign Check Report</b-dropdown-item>
                            <b-dropdown-item to="/email"><i class="fa fa-clipboard"></i> Email Logs</b-dropdown-item>
                            <b-dropdown-item to="/errorReports"><i class="fa fa-clipboard"></i> Error Reports</b-dropdown-item>

                            <!-- F -->
                            <b-dropdown-item to="/ftp/logs"><i class="fa fa-clipboard"></i> Ftp Logs</b-dropdown-item>

                            <!-- H -->
                            <b-dropdown-item to="/campaign/hits-report"><i class="fa fa-clipboard"></i> Hits Report</b-dropdown-item>

                            <!-- I -->
                            <b-dropdown-item to="/reports/ip"><i class="fa fa-clipboard"></i> Ip Report </b-dropdown-item>

                            <!-- L -->
                            <b-dropdown-item to="/proxy/lat-long-calculation/report"><i class="fa fa-clipboard"></i> Latitude and Longitude Distance Calculation Report</b-dropdown-item>
                            <b-dropdown-item to="/logs"><i class="fa fa-clipboard"></i> Logs</b-dropdown-item>

                            <!-- M -->
                            <b-dropdown-item to="/metrics"><i class="fa fa-clipboard"></i> Metrics</b-dropdown-item>

                            <!-- P -->
                            <b-dropdown-item to="/proxyRequest"><i class="fa fa-clipboard"></i> Proxy Tracking Information</b-dropdown-item>
                            <b-dropdown-item to="/performance/report"><i class="fa fa-clipboard"></i> Performance Report</b-dropdown-item>
                            <b-dropdown-item to="/reports/privacy"><i class="fa fa-clipboard"></i> Privacy Policy Report </b-dropdown-item>
                            <b-dropdown-item to="/campaign/report"><i class="fa fa-clipboard"></i> Provider Report</b-dropdown-item>
                            <b-dropdown-item to="/reports/proxyreport"><i class="fa fa-clipboard"></i> Proxy Report </b-dropdown-item>
                            <b-dropdown-item to="/proxy/performance-report"><i class="fa fa-clipboard"></i> Proxy Performance Report</b-dropdown-item>
                            <b-dropdown-item to="/proxy/logs"><i class="fa fa-clipboard"></i> Proxy Status Check</b-dropdown-item>
                            <b-dropdown-item to="/providerError"><i class="fa fa-clipboard"></i> Proxy Error Logs - Counts</b-dropdown-item>
                            <b-dropdown-item to="/proxy/error/logs"><i class="fa fa-clipboard"></i> Proxy Error Logs - Details</b-dropdown-item>
                            <b-dropdown-item to="/proxy/ProxyTargetingSNP/report"><i class="fa fa-clipboard"></i> Proxies Targeted To Campaigns</b-dropdown-item>

                            <!-- Q -->
                            <b-dropdown-item to="/queue/report"><i class="fa fa-clipboard"></i> Queue Report</b-dropdown-item>

                            <!-- T -->
                            <b-dropdown-item to="/errorReports/timeOnSite"><i class="fa fa-clipboard"></i> Time on Site and Bounce Report (error report)</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item to="/settings"><i class="fa fa-cog"></i> Settings</b-nav-item>

<!--                        <b-nav-item-dropdown>-->
<!--                            <template v-slot:button-content>-->
<!--                                <em><i class="fa fa-wrench"></i> Workflow</em>-->
<!--                            </template>-->
<!--                            <b-dropdown-item to="/workflow" class="text-black"><i class="fa fa-plus"></i> Create Workflow</b-dropdown-item>-->
<!--                            <b-dropdown-item to="/workflow/scanResults"><i class="fa fa-list-alt"></i> Workflow Complete Scan</b-dropdown-item>-->
<!--                        </b-nav-item-dropdown>-->
                    </b-navbar-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item-dropdown right>
                            <!-- Using 'button-content' slot -->
                            <template v-slot:button-content>
                                <em><i class="fa fa-user-secret"></i> {{user.name}}</em>
                            </template>
                            <!--<b-dropdown-item href="#"><i class="fa fa-id-card"></i> Profile</b-dropdown-item>-->
                            <b-dropdown-item href="#" @click="$root.logout"><i class="fa fa-sign-out"></i> Sign Out</b-dropdown-item>
                            <b-dropdown-item href="/profile"><i class="fa fa-user"></i> Profile</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>
	</div>
</template>

<script>
    export default {
        name: 'Nav',
        props: [
            'user'
        ]
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
