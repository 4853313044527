import Vue from 'vue'
import VueRouter from "vue-router";
import 'vue-select/dist/vue-select.css'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import swal from "sweetalert";
import DrVueEcharts from 'dr-vue-echarts'
import VueCookies from 'vue-cookies'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap'
import 'font-awesome/css/font-awesome.css'
import App from './layouts/App'
import routes from './routes'
import store from './store'
import { SERVER_URL} from './constants'
global.jQuery = require('jquery');
import * as VueGoogleMaps from 'vue2-google-maps'
var $ = global.jQuery;
window.$ = $;

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
// registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJiUX5YcnxVR2ZaVkU=');
registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkxiWn5fcnNVQ2lcU0c=');

import "charts.css";
import VueChartsCSS from "vue.charts.css";
Vue.use(VueChartsCSS);


import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
Vue.use(VueCodemirror);
// import { registerLicense } from '@syncfusion/ej2-vue-base';
// console.log('data:regLi: ', registerLicense);
// registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjWn9acH1XTmJdVkY=');
// registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkNjWn9acH1XTmJdVkY=')
import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(false);
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import { ChartPlugin } from "@syncfusion/ej2-vue-charts";
import { PivotViewPlugin } from '@syncfusion/ej2-vue-pivotview';

Vue.use(ButtonPlugin);
Vue.use(ChartPlugin);
Vue.use(PivotViewPlugin);

//Global copy method for performing Deep Copy as Object.assign does not do Deep Copy
Object.clone = function (source, target) {
    let obj = this.assign(source, target);
    let output;
    let v;
    let key;
    output = Array.isArray(obj) ? [] : {};
    for (key in obj) {
        if (!obj.hasOwnProperty(key)) continue;
        v = obj[key];
        output[key] = (typeof v === "object")
            ? ((v === null) ? null : this.clone(v))
            : v;
    }

    return output;
}

var serverUrl = SERVER_URL;

//Vue.use(GridPlugin);
Vue.use(VueRouter);
Vue.use(VueCookies)
Vue.use(BootstrapVue);
Vue.use(DrVueEcharts);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCw0PeWKcJGbC9uwk42umIF5W2vPf3IOak',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },
});

Vue.config.productionTip = false;
Vue.config.debug = true;

const router = new VueRouter({
    mode: 'history',
    routes: routes.pages // short for `routes: routes`
})

//Register filter methods
import filters from "./filters";
for(var filter in filters)
    Vue.filter(filter, filters[filter]);

new Vue({
    router,
    store,
    data: function(){
        return {
            apikey: null,
            serverUrl: serverUrl,
            preloader: false,
            preloaderMsg:'',
            countries: [],
            states: [],
            system: {},
            roles: [],
            user: null,
            swal: swal,
            axios: axios
        }
    },
    render (h) {
        return h(App)
    },
    created: function(){
        this.preloader = true;

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('accessToken');

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(error && error.response) {
                if(401 === error.response.status) {
                    if (!this.$cookies.get('refreshToken')) return this.logout()
                    axios.post(`${this.serverUrl}/api/auth/refresh`, {refreshToken: this.$cookies.get('refreshToken')}).then((response) => {
                        if (response.data && response.data.accessToken) {
                            this.$cookies.set('accessToken', response.data.accessToken);
                            this.$cookies.set('refreshToken', response.data.refreshToken);
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                            this.$router.go(this.$router.currentRoute);
                        } else {
                            this.logout();
                        }
                    }).catch(() => {
                        this.logout();
                    })
                } else {
                    return Promise.reject(error)
                }
            }
        });
        store.dispatch('setUser')
        store.dispatch('setSettings');

        this.loadStates().then(this.loadCountries).then(this.loadRoles)
    },
    methods:{
        isAuth: function(){
            //Check if the user is logged in
            if(!localStorage.getItem('apikey')){
                if(this.$route.path != "/login"){
                    this.$router.push("/login");
                }
            }
        },
        load: function(){
            //Load the profile
            if(!this.apikey){
                this.logout();
                return ; //Promise.reject();
            }

            return axios.get(`${this.serverUrl}/admin/profile`, {params:{apikey: this.apikey}}).then(function(response){
                if(response.data && response.data.data) {
                    this.user = response.data.data;
                }
            }.bind(this)).catch(function (err) {
                console.error(err.message);
            }.bind(this))
        },
        loadCountries: function(){
            //Load the profile
            return axios.get(`${this.serverUrl}/admin/countries`, {params:{apikey: this.apikey}}).then(function(response){
                if(response.data && response.data.data) {
                    this.countries = response.data.data||[];
                }
            }.bind(this)).catch(function (err) {
                console.error(err.message);
            }.bind(this))
        },
        loadStates: function(){
            //Load the states
            return axios.get(`${this.serverUrl}/admin/states`, {params:{apikey: this.apikey}}).then(function(response){
                if(response.data && response.data.data) {
                    this.states = response.data.data||[];
                }
            }.bind(this)).catch(function (err) {
                console.error(err.message);
            }.bind(this))
        },
        loadRoles: function(){
            //Load the roles
            return axios.get(`${this.serverUrl}/admin/roles/list`, {params:{apikey: this.apikey}}).then(function(response){
                if(response.data && response.data.data) {
                    this.roles = response.data.data||[];
                }
            }.bind(this)).catch(function (err) {
                console.error(err.message);
            }.bind(this))
        },
        logout: function(){
            console.log("Signing out user")
            store.dispatch('removeUser')
            this.apikey = null;
            this.user = null;
            localStorage.clear();
            document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
            if(this.$route.path != "/login")
                this.$router.push('/login')
        }
    }
}).$mount('#app');
