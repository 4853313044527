import Vue from "vue";
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard");
const Campaigns = () => import(/* webpackChunkName: "campaigns" */ "@/pages/Campaigns");
const Campaign = () => import(/* webpackChunkName: "campaign" */ "@/pages/Campaign");
const ReportCampaignProvider = () => import(/* webpackChunkName: "campaign-report" */ "@/pages/Campaign.Report");
const ReportCampaign = () => import(/* webpackChunkName: "campaign-campaign-report" */ "@/pages/Campaign.CampaignReport");
const ReportECampaignCheck = () => import(/* webpackChunkName: "campaign-ecampaign-check-report" */ "@/pages/Campaign.ECampaignCheckReport");
const ReportDuplicateReport = () => import(/* webpackChunkName: "report-duplicate-report" */ "@/pages/Campaign.DuplicateCampaignReport");
const CampaignView = () => import(/* webpackChunkName: "campaign-view" */ "@/pages/Campaignview");
const Logs = () => import(/* webpackChunkName: "logs" */ "@/pages/Logs");
const Devices = () => import(/* webpackChunkName: "devices" */ "@/pages/Devices");
const Handers = () => import(/* webpackChunkName: "handers" */ "@/pages/Handlers");
const Nodes = () => import(/* webpackChunkName: "nodes" */ "@/pages/Nodes");
const Proxies = () => import(/* webpackChunkName: "proxies" */ "@/pages/Proxies");
const Users = () => import(/* webpackChunkName: "users" */ "@/pages/Users");
const Clients = () => import(/* webpackChunkName: "clients" */ "@/pages/Clients");
const Providers = () => import(/* webpackChunkName: "providers" */ "@/pages/Providers");
const Settings = () => import(/* webpackChunkName: "settings" */ "@/pages/Settings");
const Profile = () => import(/* webpackChunkName: "profile" */ "@/pages/Profile");
const Login = () => import(/* webpackChunkName: "login" */ "@/pages/Login");
const NotFound = () => import(/* webpackChunkName: "not-found" */ "@/pages/NotFound");
const ServerError = () => import(/* webpackChunkName: "server-error" */ "@/pages/InternalServerError");
const Workers = () => import(/* webpackChunkName: "workers.Report" */ "@/pages/workers.Report");
const Summary = () => import(/* webpackChunkName: "Summary" */ "@/pages/Summary");
const QueueReport = () => import(/* webpackChunkName: "QueueReport" */ "@/pages/Queue.Report");
const ProxyReport = () => import(/* webpackChunkName: "ProxyReport" */ "@/pages/Proxy.Report");
const ProxyLog = () => import(/* webpackChunkName: "ProxyLog" */ "@/pages/Proxy.LogReport");
const ProxyManager = () => import(/* webpackChunkName: "ProxyManager" */ "@/pages/ProxyManager");
const CoverageReport = () => import(/* webpackChunkName: "CoverageReport" */ "@/pages/coverage.Report");
const CoverageMap = () => import(/* webpackChunkName: "CoverageMap" */ "@/pages/CoverageMap");
const PerformanceReport = () => import(/* webpackChunkName: "PerformanceReport" */ "@/pages/Performance.Report");
const CappedAndShownReport = () => import(/* webpackChunkName: "CappedAndShownReport" */ "@/pages/CappedAndShownReport");
const PerformanceClickReport = () => import(/* webpackChunkName: "PerformanceClickReport" */ "@/pages/PerformanceClick.Report");
const DistributionTest = () => import(/* webpackChunkName: "DistributionTest" */ "@/pages/DistributionTest");
const ProductManage = () => import(/* webpackChunkName: "ProductManage" */ "@/pages/ProductManagement");
const NeedLogReport = () => import(/* webpackChunkName: "NeedLogReport" */ "@/pages/needsLogReport");
const Device = () => import(/* webpackChunkName: "Device" */ "@/pages/Device");
const ErrorReport = () => import(/* webpackChunkName: "websiteScripts" */ "@/pages/websiteScripts");
// import ClickDistribution from "@/pages/ClickDistribution.Report";
import InternalBilling from "@/pages/tools/InternalBilling";
import fulfillment from "@/pages/tools/fulfillmentReport";
import proxyReport from "@/pages/Proxyreport";
import Chats from "@/pages/ChatGPT/chats"
import Chat from "@/pages/ChatGPT/chat"
import Scraper from "@/pages/Scraper"
import ScraperResult from "@/pages/ScraperResults"
const ClickDistributionB = () => import(/* webpackChunkName: "ClickDistributionB" */ "@/pages/tools/ClickDistribution.breakdown");
const FoldTest = () => import(/* webpackChunkName: "FoldTest" */ "@/pages/FoldTest");
const BrowserSearch = () => import(/* webpackChunkName: "GoogleSearch" */ "@/pages/tools/browser_search/BrowserSearch.vue");
const Shown = () => import(/* webpackChunkName: "Shown" */ "@/pages/shown");
const Manager = () => import(/* webpackChunkName: "Manager" */ "@/pages/Manager");
const CampaignStatisticsReport = () => import(/* webpackChunkName: "CampaignStatisticsReport" */ "@/pages/CampaignStatisticsReport");
const HitsReport = () => import(/* webpackChunkName: "HitsReport" */ "@/pages/HitsReport");
const FtpReport = () => import(/* webpackChunkName: "FtpReport" */ "@/pages/FtpReport");
const Schedule = () => import(/* webpackChunkName: "Schedule" */ "@/pages/Click.Scheduler");
const ProxyPerformanceReport = () => import(/* webpackChunkName: "ProxyPerformanceReport" */ "@/pages/Proxy.PerformanceReport");
const LatLongCalcReport = () => import(/* webpackChunkName: "LatLongCalcReport" */ "@/pages/LatLongCalcReport");
const ProxyTargetingSNPReport = () => import(/* webpackChunkName: "ProxyTargetingSNPReport" */ "@/pages/ProxyTargetingSNPReport");
const IpBlacklist = () => import(/* webpackChunkName: "IpBlacklist" */ "@/pages/IpBlacklist");
const Bandwidth = () => import(/* webpackChunkName: "Bandwidth" */ "@/pages/Proxy.BandwidthReport");
const Email = () => import(/* webpackChunkName: "Email" */ "@/pages/emailPreview");
const DomainConfig = () => import(/* webpackChunkName: "DomainConfig" */ "@/pages/DomainConfig");
const campaignErrors = () => import(/* webpackChunkName: "campaignErrors" */ "@/pages/CampaignErrors");
const ProductConfig = () => import(/* webpackChunkName: "ProductConfig" */ "@/pages/ProductConfig");
const Products = () => import(/* webpackChunkName: "Products" */ "@/pages/Products");
const DomainManagement = () => import(/* webpackChunkName: "DomainManagement" */ "@/pages/DomainManagement");
const ShownRateConfiguration = () => import(/* webpackChunkName: "ShownRateConfiguration" */ "@/pages/Catchers");
const PlanListing = () => import(/* webpackChunkName: "PlanListing" */ "@/pages/PlanListing");
const ProductManagement = () => import(/* webpackChunkName: "ProductManagement" */ "@/pages/product.Management");
const adProducts = () => import(/* webpackChunkName: "adProducts" */ "@/pages/adProducts");
const creativeManagement = () => import(/* webpackChunkName: "creativeManagement" */ "@/pages/creativeManagement");
const clientManagement = () => import(/* webpackChunkName: "clientManagement" */ "@/pages/clientManagement");
const geoConfiguration = () => import(/* webpackChunkName: "geoConfiguration" */ "@/pages/geoConfiguration");
const GeoManagement = () => import(/* webpackChunkName: "GeoManagement" */ "@/pages/Geo.Management");
const providerError = () => import(/* webpackChunkName: "providerError" */ "@/pages/proxy.provider.error");
const providerErrorLogs = () => import(/* webpackChunkName: "providerErrorLogs" */ "@/pages/proxy.error.logs");
const resultReporting = () => import(/* webpackChunkName: "resultReporting" */ "@/pages/Result.Reporting");
const clientReport = () => import(/* webpackChunkName: "clientReport" */ "@/pages/ClientsReport");
const serviceReport = () => import(/* webpackChunkName: "serviceReport" */ "@/pages/Service.Report");
const workflow = () => import(/* webpackChunkName: "catchers" */ "@/pages/Workflow");
const contentConfig = () => import(/* webpackChunkName: "contentConfig" */ "@/pages/ContentManagement");
const sessionReport = () => import(/* webpackChunkName: "sessionReport" */ "@/pages/sessionValidateReport")
const workflowResult = () => import(/* webpackChunkName: "workflowResult" */ "@/pages/WorkflowResult")
const scanResults = () => import(/* webpackChunkName: "scanResults" */ "@/pages/scanResults")
const csReport = () => import(/* webpackChunkName: "csReport" */ "@/pages/ContentShieldReport")
const AlertsReport = () => import(/* webpackChunkName: "AlertsReport" */ "@/pages/AlertsReport")
const Metrics = () => import(/* webpackChunkName: "Metrics" */ "@/pages/Metrics")
const campaignDetailErrors = () => import(/* webpackChunkName: "campaignDetailErrors" */ "@/pages/DetailedCampaignErrors")
const Services = () => import(/* webpackChunkName: "Services" */ "@/pages/Services")
const Analytics = () => import(/* webpackChunkName: "Analytics" */ "@/pages/analytics")
const Precheck = () => import(/* webpackChunkName: "Precheck" */ "@/pages/Precheck")
const qso = () => import(/* webpackChunkName: "qso" */ "@/pages/tools/qso")
const qsoClient = () => import(/* webpackChunkName: "qsoClient" */ "@/pages/tools/qso-client")
const ClicksReport = () => import(/* webpackChunkName: "ClicksReport" */ "@/pages/Clicks.Report")
const CsvData = () => import(/* webpackChunkName: "CsvData" */ "@/pages/campaign.csvData")
const ipReport = () => import(/* webpackChunkName: "catchers" */ "@/pages/ipReport")
const privacyReport = () => import(/* webpackChunkName: "privacyReport" */ "@/pages/privacyReport");
const timeOnSite = () => import(/* webpackChunkName: "timeOnSite" */ "@/pages/portalReports/timeOnSite")
// import Error from '@/pages/Error';
import Debug from "@/pages/Debug";
import Debuger from "@/pages/Debugger";
import HandlerRequests from "@/pages/HandlerRequestV2";
import ClickBandwidth from "@/pages/Bandwidth";
import Captcha from "@/pages/CaptchaDetection";
import Inspector from "@/pages/tools/bandwidth/inspector";
import Analyser from "@/pages/tools/bandwidth/Analyser";
import LinkReport from "@/pages/LinkReport";

const Catchers = () => import(/* webpackChunkName: "catchers" */ "@/pages/Catchers")

const EmailConfig = () => import(/* webpackChunkName: "emailConfig" */ "@/pages/Email.Config")
const YahooIntegration =()=> import(/* webpackChunkName: "YahooIntegration" */ "@/pages/YahooIntegration")
const proxyTracking = () => import(/* webpackChunkName: "privacyReport" */ "@/pages/proxyTracking");
const cookiesReport = () => import(/* webpackChunkName: "privacyReport" */ "@/pages/cookiesReport");
const Snapshots = () => import(/* webpackChunkName: "snapshots" */ "@/pages/Snapshots")
const OverNightControl = () => import(/* webpackChunkName: "overnightConfig" */ "@/pages/tools/overnightConfig");
const RuleEngine = () => import(/* webpackChunkName: "RuleEngine" */ "@/pages/tools/Rules.Engine");
const AutomationLog = () => import(/* webpackChunkName: "AutomationLog" */ "@/pages/tools/automationlog");
const Billing = () => import(/* webpackChunkName: "Billing" */ "@/pages/Billing");
const Exemption = () => import(/* webpackChunkName: "Exempt" */ "@/pages/Exempt");
const WeeklyReport = () => import(/* webpackChunkName: "dashboard" */ "@/pages/tools/weeklyReport");
const EmailWarmerProcess = () => import(/* webpackChunkName: "process" */ "@/pages/emailWarmer/processes");
const InstagramIntegration = () => import(/* webpackChunkName: "instagramIntegration" */ "@/pages/tools/InstagramIntegration.vue");

const Social = () => import(/* webpackChunkName: "facebook" */ "@/pages/tools/scraper/Social");
const Cookie = () => import(/* webpackChunkName: "facebook" */ "@/pages/tools/scraper/Cookie");
const BingIntegration = ()  => import(/* webpackChunkName: "BingIntegration" */ "@/pages/BingIntegration");

import axios from "axios";
import store from "./store";



const guard = (to, from, next) => {
    if(Vue.$cookies.get('accessToken')) {
        next()
    } else {
        console.log("redirect to login")
        next('/login');
    }
}

export default {
    pages: [
        {
            path: '/login',
            component: Login,
            beforeEnter: (to, from, next) => {
                if(Vue.$cookies.get('accessToken')) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + Vue.$cookies.get('accessToken');
                    store.dispatch('setUser');
                    next(from.path);
                } else {
                    next();
                }
            }
        },
        {path: '/', component: Dashboard, beforeEnter: guard},
        {path: '/scraper', component: Scraper, beforeEnter: guard},
        {path: '/scraper/result', component: ScraperResult, beforeEnter: guard},
        {path: '/campaigns', component: Campaigns, beforeEnter: guard},
        {path: '/shown', component: Shown, beforeEnter: guard},
        {path: '/email', component: Email, beforeEnter: guard},
        {path: '/chats', component: Chats, beforeEnter: guard},
        {path: '/chat', component: Chat, beforeEnter: guard},
        {path: '/billing', component: Billing, beforeEnter: guard},

        {path: '/bandwidth/inspect', component: Inspector, beforeEnter: guard},
        {path: '/bandwidth/analyse', component: Analyser, beforeEnter: guard},

        //reports
        {path: '/queue/report', component: QueueReport, beforeEnter: guard},
        {path: '/coverage/report', component: CoverageReport, beforeEnter: guard},
        {path: '/proxy/report/:id', component: ProxyReport, beforeEnter: guard},
        {path: '/proxy/performance-report', component: ProxyPerformanceReport, beforeEnter: guard},
        {path: '/proxy/logs', component: ProxyLog, beforeEnter: guard},
        {path: '/proxy-manager', component: ProxyManager, beforeEnter: guard},
        {path: '/proxy/bandwidth-report', component: Bandwidth, beforeEnter: guard},
        {path: '/domain/bandwidth', component: ClickBandwidth, beforeEnter: guard},
        {path: '/captcha/detection', component: Captcha, beforeEnter: guard},
        {path: '/campaign/hits-report', component: HitsReport, beforeEnter: guard},
        {path: '/campaign/report', component: ReportCampaignProvider, beforeEnter: guard},
        {path: '/campaign/campaign-report', component: ReportCampaign, beforeEnter: guard},
        {path: '/campaign/campaign-statistics-report', component: CampaignStatisticsReport, beforeEnter: guard},
        {path: '/campaign/e-campaign-report', component: ReportECampaignCheck, beforeEnter: guard},
        {path: '/campaign/d-campaign-report', component: ReportDuplicateReport, beforeEnter: guard},
        {path: '/performance/report', component: PerformanceReport, beforeEnter: guard},
        {path: '/performance/click-report', component: PerformanceClickReport, beforeEnter: guard},
        {path: '/capped-and-shown-report', component: CappedAndShownReport, beforeEnter: guard},
        {path: '/campaign/needs', component: NeedLogReport, beforeEnter: guard},
        {path: '/errorReports', component: ErrorReport, beforeEnter: guard},

        {path: '/device/health', component: Device, beforeEnter: guard},

        //portal reports
        {path: '/errorReports/timeOnSite', component: timeOnSite, beforeEnter: guard},
        {path: '/link-breakdown/:id', component: LinkReport, beforeEnter: guard},

        // Inbox Enhanced
        {path: '/email-warmer/processes', component: EmailWarmerProcess, beforeEnter: guard},

        {path: '/campaign/workers', component: Workers, beforeEnter: guard},
        {path: '/coverage/map', component: CoverageMap, beforeEnter: guard},
        {path: '/campaign/:id', component: Campaign, beforeEnter: guard},
        {path: '/campaignview', component: CampaignView, beforeEnter: guard},
        {path: '/sessionReport', component: sessionReport, beforeEnter: guard},
        {path: '/providerError', component: providerError, beforeEnter: guard},
        {path: '/ftp/logs', component: FtpReport, beforeEnter: guard},
        {path: '/logs', component: Logs, beforeEnter: guard},
        {path: '/proxies', component: Proxies, beforeEnter: guard},
        {path: '/providers', component: Providers, beforeEnter: guard},
        {path: '/proxy/error/logs', component: providerErrorLogs, beforeEnter: guard},
        {path: '/devices', component: Devices, beforeEnter: guard},
        { path: '/handlers', component: Handers, beforeEnter: guard},
        { path: '/handlers/request', component: HandlerRequests, beforeEnter: guard},
        {path: '/ip-blacklist', component: IpBlacklist},
        {path: '/nodes', component: Nodes, beforeEnter: guard},
        {path: '/users', component: Users, beforeEnter: guard},
        {path: '/profile', component: Profile, beforeEnter: guard},
        {path: '/clients', component: Clients, beforeEnter: guard},
        {path: '/clientsReport', component: clientReport, beforeEnter: guard},
        {path: '/settings', component: Settings, beforeEnter: guard},
        {path: '/summary', component: Summary, beforeEnter: guard},
        {path: '/domain/configuration', component: DomainConfig, beforeEnter: guard},
        {path: '/product/configuration', component: ProductConfig, beforeEnter: guard},
        {path: '/products', component: Products, beforeEnter: guard},
        {path: '/domain/management', component: DomainManagement, beforeEnter: guard},
        {path: '/shown-rate-config', component: ShownRateConfiguration, beforeEnter: guard},
        {path: '/geoCampaignManagement', component: GeoManagement, beforeEnter: guard},
        {path: '/adProducts', component: adProducts, beforeEnter: guard},
        {path: '/productManagement', component: ProductManagement, beforeEnter: guard},
        {path: '/product/management', component: ProductManage, beforeEnter: guard},
        {path: '/creativeManagement', component: creativeManagement, beforeEnter: guard},
        {path: '/serviceReport', component: serviceReport, beforeEnter: guard},
        {path: '/clientManagement', component: clientManagement, beforeEnter: guard},
        {path: '/geoConfiguration', component: geoConfiguration, beforeEnter: guard},
        {path: '/plan/listing', component: PlanListing, beforeEnter: guard},
        {path: '/workflow', component: workflow, beforeEnter: guard},
        {path: '/workflow/configuration', component: contentConfig, beforeEnter: guard},
        {path: '/workflow/results', component: workflowResult, beforeEnter: guard},
        {path: '/workflow/scanResults', component: scanResults, beforeEnter: guard},
        {path: '/workflow/report', component: csReport, beforeEnter: guard},
        {path: '/campaign/error/logs', component: campaignErrors, beforeEnter: guard},
        {path: '/campaign/detailed/error/logs', component: campaignDetailErrors, beforeEnter: guard},
        {path: '/tools/qso', component: qso, beforeEnter: guard},
        {path: '/tools/fulfillmentReport', component: fulfillment, beforeEnter: guard},
        {path: '/tools/qso-client', component: qsoClient, beforeEnter: guard},
        {path: '/tools/internal-billing', component: InternalBilling, beforeEnter: guard},
        {path: '/proxy/lat-long-calculation/report', component: LatLongCalcReport, beforeEnter: guard},
        {path: '/browser-search', component: BrowserSearch, beforeEnter: guard},
        {path: '/clickDistribution/report', component: ClickDistributionB, beforeEnter: guard},

        // {path: '/clickDistribution/breakdown', component: ClickDistributionB, beforeEnter: guard},
        {path: '/proxy/ProxyTargetingSNP/report', component: ProxyTargetingSNPReport, beforeEnter: guard},
        {path: '/manager', component: Manager, beforeEnter: guard},
        {path: '/resultReporting', component: resultReporting, beforeEnter: guard},
        {path: '/reports/alerts', component: AlertsReport, beforeEnter: guard},
        {name: 'schedule', path: '/click/schedule', component: Schedule, beforeEnter: guard},
        {name: 'services', path: '/services', component: Services, beforeEnter: guard},

        // {path: '/campaignErrors', component: campaignErrors, beforeEnter: guard},
        {name: '/schedule', path: '/click/schedule', component: Schedule, beforeEnter: guard},
        {name: '/metrics', path: '/metrics', component: Metrics, beforeEnter: guard},
        {name: '/distribution', path: '/distribution', component: DistributionTest, beforeEnter: guard},
        {name: '/fold', path: '/fold', component: FoldTest, beforeEnter: guard},

        {name: '/debugger', path: '/debugger', component: Debuger, beforeEnter: guard},
        {name: '/debug', path: '/debug', component: Debug, beforeEnter: guard},

        {name: 'proxyreport', path: '/reports/proxyreport', component: proxyReport, beforeEnter: guard},
        {name: '/clicks/report', path: '/clicks/report', component: ClicksReport, beforeEnter: guard},
        {name: '/snapshots',path: '/snapshots',component: Snapshots,beforeEnter: guard},

        {name: '/nightlyConfig',path: '/nightlyConfig',component: OverNightControl,beforeEnter: guard},
        {name: '/ruleEngine',path: '/ruleEngine',component: RuleEngine,beforeEnter: guard},
        {name: '/automationLog',path: '/automationLog',component: AutomationLog,beforeEnter: guard},
        {name: '/weeklyReport',path: '/weeklyReport',component: WeeklyReport,beforeEnter: guard},


        {name: '/scraperSocial', path: '/scraper/social', component: Social, beforeEnter: guard},
        {name: '/scraperCookie', path: '/scraper/cookies', component: Cookie, beforeEnter: guard},

        {name: '/exemption', path: '/exemption',component: Exemption, beforeEnter: guard},
        {name: '/proxyRequest', path: '/proxyRequest',component: proxyTracking, beforeEnter: guard},
        {name: '/cookiesReport', path: '/cookiesReport',component:  cookiesReport, beforeEnter: guard},

        //ADOPS-47
        {name: 'reports-catchers', path: '/reports/catchers', component: Catchers, beforeEnter: guard},
        {name: 'reports-analytics', path: '/reports/analytics', component: Analytics, beforeEnter: guard},
        {name: 'reports-ip', path: '/reports/ip', component: ipReport, beforeEnter: guard},
        {name: 'reports-privacy', path: '/reports/privacy', component: privacyReport, beforeEnter: guard},
        {name: 'precheck', path: '/precheck', component: Precheck, beforeEnter: guard},
        {name: 'csvdata', path: '/campaigns/csvdata', component: CsvData, beforeEnter: guard},
        {path: '/404', component: NotFound},
        {path: '/500', component: ServerError},
        {path: '*', component: NotFound, beforeEnter: guard},


        //email Warmer
        { name: 'email-config', path: '/emailconfig', component: EmailConfig, beforeEnter: guard },
        
        {name: 'instagramIntegration', path: '/instagramIntegration', component: InstagramIntegration, beforeEnter: guard},
   

        //integrations
        {name: 'BingIntegration', path: '/BingIntegration', component: BingIntegration, beforeEnter: guard},


        {name: 'yahoo-integration', path: '/yahooIntegration', component: YahooIntegration, beforeEnter: guard},
    ]
}
